import Messages from "./Messages";
import {useContext, useEffect, useState} from "react";
import {authContext} from "../../context/auth";
import useBackDrops from "../../hooks/useBackDrops";
import PlaceHolders from "./PlaceHolders";
import PlaceHolderSelector from "../../components/PlaceHolderSelector";
import PreviewFile from "../../components/FileSelector/PreviewFile";
import ModelSelector from "../../components/ModelSelector";
import usePlaceHolders from "../../hooks/usePlaceHolders";
import {apiKeyContext} from "../../context/apiKey";
import Spinner from "../../components/Spinner";

export default function TestPrompt({prompt, placeHolders}) {
	const {token} = useContext(authContext);
	const [model, setModel] = useState("");
	const [jobPlaceHolders, setJobPlaceHolders] = useState( []);
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const {openAiApiKey, anthropicApiKey, mistralApiKey} = useContext(apiKeyContext);

	useBackDrops();

	useEffect(() => {
		setJobPlaceHolders([]);
	}, [placeHolders]);

	const id = `testPrompt${prompt.id}`;

	function onChangePlaceHolder(placeHolderId, value) {
		setJobPlaceHolders(prev => {
			const newState = [...prev];
			const foundIndex = newState.findIndex(value => value.placeHolderId === placeHolderId);
			if (foundIndex !== -1) {
				newState[foundIndex].value = value;
			}
			else {
				newState.push({
					placeHolderId,
					value,
					placeHolderName: placeHolders.find(value => value.id === placeHolderId).name
				});
			}
			return newState;
		});
	}

	async function onSubmit(evt) {
		evt.preventDefault();

		setLoading(true);
		setError(null);

		try {
			const response = await fetch(process.env.REACT_APP_ENDPOINT + '/prompts/' + prompt.id + '/test', {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + token
				},
				body: JSON.stringify({
					jobPlaceHolders,
					openAiApiKey,
					anthropicApiKey,
					mistralApiKey,
					model
				})
			});
			if (response.status === 200) {
				const {result} = await response.json();
				setResult(result);
			}
			else {
				throw new Error("Wrong status code.");
			}
		}
		catch (e) {
			console.error(e);
			setError("Failed to process your request.");
		}

		setLoading(false);
	}

	return (
		<>
			<button type="button" className="btn btn-secondary btn-sm" data-bs-toggle="modal" data-bs-target={`#${id}`}>
				Test prompt
			</button>

			<div className="modal fade" id={id} tabIndex="-1" aria-labelledby={`${id}Label`} aria-hidden="true">
				<div className="modal-dialog modal-xl">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id={`${id}Label`}>Test prompt</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<form onSubmit={onSubmit}>
								{placeHolders.length > 0 &&
									placeHolders.map(placeHolder => {
										return <div key={placeHolder.id} className={"mb-3"}>
											<label htmlFor={"placeHolders" + placeHolder.id}
												   className={"form-label"}>{placeHolder.name}:</label>
											<textarea required={true} id={"placeHolders" + placeHolder.id} className={"form-control"}
												   onChange={(evt) => {
													   onChangePlaceHolder(placeHolder.id, evt.target.value);
												   }}
												   value={jobPlaceHolders.find(value => value.placeHolderId === placeHolder.id)?.value || ''}/>
										</div>;
									})
								}
								{(placeHolders.length === jobPlaceHolders.length && placeHolders.length > 0) &&
									<>
										<ModelSelector model={model} setModel={setModel}/>
									</>
								}
								{result &&
									<>
										<hr/>
										<textarea className={"form-control mt-3"} readOnly={true}
												  value={result}></textarea>
									</>
								}
								{model &&
									<>
										<hr/>
										<button type={"submit"} className={"btn btn-success w-100"} disabled={loading}>{loading && <Spinner small={true} />} Test prompt</button>
									</>
								}

							</form>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
